
.single-data{
  height: calc(100vh - 260px);
  //height: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 20px 20px;
  .single-data-header{
    display: flex;
    align-items: center;
    span{
      font-size: 14px;
      color: #333;
      margin-left: 12px;
    }
  }
}
::v-deep .like-look-dialog{
  .el-dialog__header{
    margin:0 20px;
    padding: 20px 0;
    color: #333;
    font-size: 18px;
    border-bottom: #DDDDDD 1px solid;
  }
  .el-dialog__body{
    padding: 20px 20px 60px;
    .look-dialog-name{
      font-size: 18px;
      color: #333;
      text-align: center;
    }
  }
}
.line-live-info{
  width: 100%;
  display: flex;
  .live-info-img{
    width: 105px;
    height: 130px;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  .live-info-content{
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1;
    flex: 1;
    width: 1%;
    span{
      color: #999999;
      font-size: 12px;
      &:nth-child(1){
        color: #333;
        font-size: 16px;
      }
      &:nth-child(2){
        margin: 18px 0;
      }
    }
  }
}
